@import "~@flaticon/flaticon-uicons/css/all/all";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  
  background-color: #ffffff;
}

#root {
  width: 100%;
  height: 100%;
}

button {
  transition-duration: 75ms;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}